import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "gatsby";

import Layout from "../components/layout";

export default () => {
  return (
    <Layout title={`Page Not Found`}>
      <Container>
        <Row className="mt-5">
          <Col className="text-center mt-5">
            <h1>The page doesn't exist :(</h1>
            <div className={`mt-5`}>
              <Image src={`/images/whale.png`} width="300" />
            </div>
            <Link to="/">Back to home</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
